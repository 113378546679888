<template>
  <div class="px-6 lg:pt-10 lg:pb-32 lg:px-32 py-10">
    <h4 class="font-bold text-5xl lg:mb-20 mb-10">Contact Us</h4>
    <div class="flex flex-wrap">
      <!-- Left col-->
      <div class="w-full lg:w-1/2 mb-10 lg:mb-0">
        <div class="flex flex-col lg:w-2/3 xl:w-1/3">
          <a class="pb-3 border-b inline-block border-pink-500 text-2xl mb-12" href="#">support@guestio.com</a>
          <div class="text-blue-800 flex">
            <a class="mr-5" href="#">
              <RoundedFacebookIcon classes="w-10 h-10"/>
            </a>
            <a class="mr-5" href="#">
              <RoundedTwitterIcon classes="w-10 h-10"/>
            </a>
            <a class="mr-5" href="#">
              <RoundedInstagramIcon classes="w-10 h-10"/>
            </a>
            <a class="mr-5" href="#">
              <RoundedLinkedinIcon classes="w-10 h-10"/>
            </a>
          </div>
        </div>
      </div>
      <!-- Right col-->
      <ValidationObserver  class="w-full lg:w-1/2" ref="contactUsForm" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(sendEmail)">
          <div class="lg:w-9/12 grid grid-cols-1 row-gap-10">
            <div class="w-full">
              <label for="email" class="font-medium text-sm mb-1 block">Your e-mail address</label>
              <ValidationProvider rules="required" name="Email" vid="email" v-slot="{ errors }">
                <TextInput v-model="form.email" type="email" :errors="errors" placeholder="e.g. mail@example.com" id="email"/>
              </ValidationProvider>
            </div>
            <div class="w-full">
              <label for="email" class="font-medium text-sm mb-1 block">Subject</label>
              <ValidationProvider rules="required" name="Subject" vid="subject" v-slot="{ errors }">
                <TextInput v-model="form.subject" type="text" :errors="errors" id="subject"/>
              </ValidationProvider>
            </div>
            <div class="w-full">
              <label for="email" class="font-medium text-sm mb-1 block">How can we help?</label>
              <ValidationProvider mode="lazy" rules="required" name="text" vid="text" v-slot="{ errors }">
                <div>
                  <textarea-autosize class="shadow-sm guestio-form-textarea" name="text" id="text" v-model="form.text" :min-height="200" />
                </div>
                <p class="text-red-500 text-sm font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <button type="submit" class="bg-indigo-gradiant rounded-full text-white font-semibold py-3 justify-center flex">
              <span v-if="loading">
                <loading-icon class="h-5 w-5 mr-4"/>
              </span>
              <span v-else>Send</span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import RoundedFacebookIcon from "@/components/svgs/RoundedFacebookIcon";
import RoundedTwitterIcon from "@/components/svgs/RoundedTwitterIcon";
import RoundedInstagramIcon from "@/components/svgs/RoundedInstagramIcon";
import RoundedLinkedinIcon from "@/components/svgs/RoundedLinkedinIcon";
import TextInput from "@/components/shared/TextInput";
import api from '@/api'

export default {
  name: "Contact",
  components: {
    RoundedLinkedinIcon,
    RoundedInstagramIcon,
    RoundedTwitterIcon,
    RoundedFacebookIcon,
    TextInput
  },

  data() {
    return {
      loading: false,
      form: {
        email: '',
        subject: '',
        text: '',
      },
    }
  },

  methods: {
    sendEmail() {
      this.loading = true

      api.post(`/contact-us`, this.form)
      .then(() => {
        const success = { message: 'Your request was successfully submitted. Thank you!'}
        this.$router.push({name: 'Home'})
        window.Event.$emit('toast', success)
      }).catch(() => {
        const error = { message: 'Something went wrong. Try again in a few minutes.', type: 'error'}
        window.Event.$emit('toast', error)
      }).finally(() => this.loading = false)
    },
  },
}
</script>

<style scoped>

</style>
